import agroFarm from '../assets/AgroFarm.jpg'
import eventPlanner from '../assets/EventPlanner.jpg'
import cocoPeat from '../assets/Cocopeat.jpg'
import photography from '../assets/Photography.jpg'
import fitness from '../assets/Fitness.jpg'

const Portfolio = () => {
  return (
    <div className="mx-1 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-12 flex flex-col py-[20px] gap-4 lg:gap-10">
      <div className="flex flex-col items-center text-center gap-4">
        <h2 className="px-2 text-2xl leading-10 md:text-4xl font-bold md:leading-[80px] text-primary">
          Our Portfolio: Showcasing Our Best Works
        </h2>
        <p className="w-full md:w-[70%] px-2 text-sm md:text-lg leading-6 text-gray-500">
          At Vedid, we take pride in delivering custom, high-quality digital
          solutions that drive results. From sleek eCommerce stores to
          interactive 3D websites, our projects reflect our commitment to
          excellence, creativity, and client satisfaction.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-10">
        <div className="bg-[#ECF2F0] p-4 rounded-xl items-start hover:shadow-md flex flex-col gap-4">
          <img
            src={agroFarm}
            alt="Real Estate Website Design"
            className="w-full rounded-md"
          />
          <p className="text-xl text-primary font-medium">AgroFarm Website</p>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="px-3 py-2 bg-secondary text-white font-medium rounded-lg"
              onClick={() =>
                window.open(
                  'https://wide-situation-8cb.notion.site/Agro-Farm-Website-Home-Page-Sample-12988ede135380a7834de50ab05a4b9c?pvs=4',
                  '_blank'
                )
              }
            >
              Preview
            </button>
            <button
              type="button"
              className="px-3 py-2 bg-primary text-tertiary font-medium rounded-lg"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              Start Your Project
            </button>
          </div>
        </div>
        <div className="bg-[#ECF2F0] p-4 rounded-xl items-start hover:shadow-md flex flex-col gap-4">
          <img
            src={eventPlanner}
            alt="Real Estate Website Design"
            className="w-full rounded-md"
          />
          <p className="text-xl text-primary font-medium">
            EventPlanner Website
          </p>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="px-3 py-2 bg-secondary text-white font-medium rounded-lg"
              onClick={() =>
                window.open(
                  'https://wide-situation-8cb.notion.site/Event-Organizer-Website-Sample-Home-Page-12188ede135380359158e644d03db129?pvs=4',
                  '_blank'
                )
              }
            >
              Preview
            </button>
            <button
              type="button"
              className="px-3 py-2 bg-primary text-tertiary font-medium rounded-lg"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              Start Your Project
            </button>
          </div>
        </div>
        <div className="bg-[#ECF2F0] p-4 rounded-xl items-start hover:shadow-md flex flex-col gap-4">
          <img
            src={cocoPeat}
            alt="Real Estate Website Design"
            className="w-full rounded-md"
          />
          <p className="text-xl text-primary font-medium">Cocopeat Website</p>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="px-3 py-2 bg-secondary text-white font-medium rounded-lg"
              onClick={() =>
                window.open(
                  'https://wide-situation-8cb.notion.site/Cocopeat-Website-Sample-Home-Page-9d0ae3520a9b40b3a2bf4fd53d2f7b52?pvs=4',
                  '_blank'
                )
              }
            >
              Preview
            </button>
            <button
              type="button"
              className="px-3 py-2 bg-primary text-tertiary font-medium rounded-lg"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              Start Your Project
            </button>
          </div>
        </div>
        <div className="bg-[#ECF2F0] p-4 rounded-xl items-start hover:shadow-md flex flex-col gap-4">
          <img
            src={photography}
            alt="Photography Website Design"
            className="w-full rounded-md"
          />
          <p className="text-xl text-primary font-medium">
            Photography Website
          </p>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="px-3 py-2 bg-secondary text-white font-medium rounded-lg"
              onClick={() =>
                window.open(
                  'https://wide-situation-8cb.notion.site/Photographer-Website-Sample-Home-Page-12188ede135380419355c6ad9cd0340b?pvs=4',
                  '_blank'
                )
              }
            >
              Preview
            </button>
            <button
              type="button"
              className="px-3 py-2 bg-primary text-tertiary font-medium rounded-lg"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              Start Your Project
            </button>
          </div>
        </div>
        <div className="bg-[#ECF2F0] p-4 rounded-xl items-start hover:shadow-md flex flex-col gap-4">
          <img
            src={fitness}
            alt="Fitness Website Design"
            className="w-full rounded-md"
          />
          <p className="text-xl text-primary font-medium">Fitness Website</p>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="px-3 py-2 bg-secondary text-white font-medium rounded-lg"
              onClick={() =>
                window.open(
                  'https://wide-situation-8cb.notion.site/Fitness-Website-Design-Sample-14188ede13538019b17dd7423272f30b?pvs=4',
                  '_blank'
                )
              }
            >
              Preview
            </button>
            <button
              type="button"
              className="px-3 py-2 bg-primary text-tertiary font-medium rounded-lg"
              onClick={() =>
                window.open('https://calendly.com/vedidinfo16/30min', '_blank')
              }
            >
              Start Your Project
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
